
import { Component, Vue } from 'vue-property-decorator'
import { KeepAlive } from '../../../utils/decorators'
import CustomExport from '../customExport/Index.vue'
import TypeSet from './TypeSet.vue'

@Component({
  components: { TypeSet, CustomExport }
})
@KeepAlive
export default class PointList extends Vue {
  private searchForm: { keyWord: string; facilityTypeId: string; projectId: string } = {
    keyWord: '',
    facilityTypeId: (this.$route.query.facilityTypeId as string) || '',
    projectId: (this.$route.query.projectId as string) || ''
  }

  private isShow = false
  private loading = false
  private typeList = []
  private tableData = []
  page = 1
  size = 10
  total = 0
  get projectList () {
    return this.$store.state.projectList
  }

  created () {
    this.getType()
    this.loadData()
  }

  // 设施类型列表
  getType () {
    this.$axios.get(this.$apis.patrol.selectFacilityTypeByList).then(res => {
      this.typeList = res.list || []
    })
  }

  loadData () {
    this.loading = true
    this.$axios.get(this.$apis.patrol.selectFacilityByPage, {
      page: this.page,
      size: this.size,
      ...this.searchForm
    }).then(res => {
      this.tableData = res.list
      this.total = res.total
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.loadData()
  }

  onAdd () {
    this.$router.push('/patrol/patrolPoint/add')
  }

  onDetail (id: string) {
    this.$router.push({ name: 'patrolPointDetail', params: { id } })
  }

  onEdit (id: string) {
    this.$router.push({
      name: 'patrolPointEdit',
      params: { id }
    })
  }

  onDelete (facilityId: string) {
    this.$confirm('确认删除该巡查点位，是否继续？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.patrol.deleteFacility, {
        facilityId
      }).then(() => {
        this.loadData()
      })
    })
  }
}
